var world = this;
$( ".step-container" ).mouseover(function() {

let elemntsToActive = $(this).closest(".step-container");

  if(elemntsToActive.length > 0){ 
    higlighted(elemntsToActive);
  }

});
$( ".step-container" ).mouseout(function() {
  let elemntsToDesactive = $(this).closest(".step-container");

  if(elemntsToDesactive.length > 0){ 
    removeHilighted(elemntsToDesactive);
  }

});
function higlighted(row){
  $(row).addClass("active-step");
}

function removeHilighted(row){

  $(row).removeClass("active-step");

}



function putOutOpacity(elements){

  elements.each(function(){
    $(this).removeClass("opacity-our-services");
    $(this).addClass("waitingOpacity");

  });


}
function putOpacityOn(elements){

  elements.each(function(){

    $(this).removeClass("waitingOpacity");
    $(this).addClass("opacity-our-services");

  });


}

